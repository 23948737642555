<template>
  <el-button @click="handleClick" size="mini" type="danger" :loading="loading">
    删除
  </el-button>
</template>

<script>
import { delBanner } from "@/api/nft.js";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    bannerType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClick() {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleDelete();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async handleDelete() {
      this.loading = true;
      await this.deleteBanner();
      this.loading = false;
      this.$message.success("删除成功", 1.5);
      this.$emit("success");
    },
    async deleteBanner() {
      try {
        await delBanner(this.id);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
