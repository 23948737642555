<template>
  <div style="padding: 20px">
    <!-- <div>
      <el-radio-group
        @change="handleStatusChange"
        v-model="bannerType"
        style="margin: 0 10px 10px 0"
      >
        <el-radio-button label="home">首页轮播</el-radio-button>
        <el-radio-button label="generation">合成轮播</el-radio-button>
        <el-radio-button label="crowdfunding">众筹轮播</el-radio-button>
      </el-radio-group>
    </div> -->
    <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-radio-group
            @change="handleStatusChange"
            v-model="status"
            style="margin-bottom: 10px;"
          >
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button :label="1">已启用</el-radio-button>
            <el-radio-button :label="-1">未启用</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="12" :offset="0">
          <div style="text-align: right">
            <el-button type="primary" size="default" @click="showAdd">
              新增
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="轮播图">
        <template slot-scope="scope" prop="pic_cn">
          <el-image
            :src="scope.row.pic_cn"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.pic_cn]"
            style="max-width: 200px; max-height: 200px;"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="轮播图（英）" prop="pic_en">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.pic_en"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.pic_en]"
            style="max-width: 200px; max-height: 200px;"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title_cn"></el-table-column>
      <el-table-column label="排序编号" prop="sort" width="80"></el-table-column>
      <el-table-column label="启用状态" prop="status" width="100">
        <template slot-scope="scope">
          <el-tag v-if="+scope.row.status === -1" type="info">未启用</el-tag>
          <el-tag v-if="+scope.row.status === 0" type="warning">
            定时启用
          </el-tag>
          <el-tag v-if="+scope.row.status === 1" type="success">启用中</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="有效时间" prop="status">
        <template slot-scope="scope">
          <div style="text-align: center;">
            <div>{{ scope.row.start_at | formatDate }}</div>
            <div style="transform: rotate(90deg);">~</div>
            <div>{{ scope.row.end_at | formatDate }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" prop="created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="指定标签" prop="user_label">
        <template slot-scope="scope">
          <div v-if="scope.row.user_label === 'none'">不指定</div>
          <div v-if="scope.row.user_label === 'social_new_user'">
            星笺新用户
          </div>
        </template>
      </el-table-column>
      <el-table-column label="指定星球或艺术家" prop="platform_label">
        <template slot-scope="scope">
          <div v-if="scope.row.platform_label === 'none'">不指定</div>
          <div v-if="scope.row.platform_label === 'official_planet'">
            官方星球
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="showEdit(scope.row)"
            style="margin-right: 10px"
          >
            编辑
          </el-button>
          <DeleteButton :id="`${scope.row.id}`" @success="fetchData" />
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="optionType === 'add' ? '新增轮播图' : '编辑轮播图'"
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
      destroy-on-close
    >
      <div v-if="visible">
        <BannerForm
          :data="editBanner"
          :id="positionId"
          :type="optionType"
          @success="handleSuccess"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getBannerList } from "@/api/nft.js";
import BannerForm from "./bannerForm.vue";
import DeleteButton from "./deleteButton.vue";
import dayjs from "dayjs";
import { UserTagMap, PlatformTagMap } from "@/utils/helper.js";

export default {
  components: {
    BannerForm,
    DeleteButton,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      status: "all",
      list: [],
      visible: false,
      editBanner: {},
      rules: {},
      optionType: "",
      positionId: "",
      // bannerType: "home", // 首页轮播home 合成轮播compose
    };
  },
  created() {
    if (this.$route.params.id) {
      const id = this.$route.params && this.$route.params.id;
      console.log(id);
      this.positionId = id;
      // todo
      // this.getNoticeById(id);
    }
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
          position_id: this.positionId,
        };

        // 状态
        if (this.status !== "all") {
          params.status = +this.status;
        }

        const { data } = await getBannerList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    closeDialog() {
      this.visible = false;
      this.editBanner = {};
      this.optionType = "";
    },
    showEdit(row) {
      this.visible = true;
      this.optionType = "edit";
      this.editBanner = row;
    },
    showAdd() {
      this.visible = true;
      this.optionType = "add";
      this.editBanner = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.pageSize = 20;
      this.page = 1;
      this.fetchData();
    },
    handleBannerTypeChange() {
      this.pageSize = 20;
      this.page = 1;
      this.fetchData();
    },
    handleSuccess() {
      this.fetchData();
      this.closeDialog();
    },
    formatEffectiveTime(start, end) {
      return (
        dayjs(start * 1000).format("YYYY-MM-DD HH:mm:ss") +
        " ~ " +
        dayjs(end * 1000).format("YYYY-MM-DD HH:mm:ss")
      );
    },
  },
};
</script>
