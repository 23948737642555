<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="150px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="标题" prop="title_cn">
        <el-input v-model="form.title_cn" placeholder="请输入轮播图标题"></el-input>
      </el-form-item>

      <el-form-item label="英文标题" prop="title_en">
        <el-input v-model="form.title_en" placeholder="请输入轮播图标题"></el-input>
      </el-form-item>

      <el-form-item label="副标题" prop="sub_title_cn">
        <el-input v-model="form.sub_title_cn" placeholder="请输入轮播图副标题"></el-input>
      </el-form-item>

      <el-form-item label="英文副标题" prop="sub_title_en">
        <el-input v-model="form.sub_title_en" placeholder="请输入轮播图英文副标题"></el-input>
      </el-form-item>

      <el-form-item label="按钮文案" prop="button_text_cn">
        <el-input v-model="form.button_text_cn" placeholder="请输入轮播图按钮文案"></el-input>
      </el-form-item>

      <el-form-item label="按钮英文文案" prop="button_text_en">
        <el-input v-model="form.button_text_en" placeholder="请输入轮播图按钮英文文案"></el-input>
      </el-form-item>

      <el-form-item label="按钮链接" prop="button_link_cn">
        <el-input v-model="form.button_link_cn" placeholder="请输入轮播图按钮链接"></el-input>
      </el-form-item>

      <el-form-item label="按钮英文链接" prop="button_link_en">
        <el-input v-model="form.button_link_en" placeholder="请输入轮播图按钮英文链接"></el-input>
      </el-form-item>

      <el-form-item label="轮播图" prop="pic_cn">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :on-success="handleUploadSuccess"
          :on-remove="handleRemove"
          :file-list="picList"
          accept="image/png,image/jpg,image/jpeg"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="英文轮播图" prop="pic_en">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :on-success="handleEnUploadSuccess"
          :on-remove="handleEnRemove"
          :file-list="enPicList"
          accept="image/png,image/jpg,image/jpeg"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="排序编号" prop="sort">
        <el-input v-model="form.sort" placeholder="请输入排序编号，最高为0"></el-input>
      </el-form-item>

      <el-form-item label="开始生效时间" prop="start_at">
        <el-date-picker
          v-model="form.start_at"
          type="datetime"
          placeholder="选择开始时间"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束生效时间" prop="end_at">
        <el-date-picker
          v-model="form.end_at"
          type="datetime"
          placeholder="选择结束时间"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="链接" prop="link">
        <el-input v-model="form.link" placeholder="请输入http/https或跳转的作品编号"></el-input>
      </el-form-item>

      <el-form-item label="英文链接" prop="link_en">
        <el-input v-model="form.link_en" placeholder="请输入http/https或跳转的作品编号"></el-input>
      </el-form-item>

      <el-form-item label="指定用户标签" prop="user_label">
        <el-select v-model="form.user_label" placeholder="请选择">
          <el-option
            v-for="item in userTags"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="指定星球或艺术家" prop="platform_label">
        <el-select v-model="form.platform_label" placeholder="请选择">
          <el-option
            v-for="item in platformTags"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="-1">禁用</el-radio>
          <el-radio :label="0">定时启用</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- <el-form-item label="类型" prop="banner_type">
        <el-radio-group v-model="form.banner_type">
          <el-radio label="home">首页轮播</el-radio>
          <el-radio label="generation">合成轮播</el-radio>
          <el-radio label="crowdfunding">众筹轮播</el-radio>
        </el-radio-group>
      </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="onSubmit">
          {{ type == "add" ? "立即创建" : "确认修改" }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addBanner, updateBanner } from "@/api/nft.js";
import { UserTagMap, PlatformTagMap } from "@/utils/helper.js";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "add",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        status: 0,
        user_label: "none",
        platform_label: "none",
      },
      rules: {
        title_cn: [{ required: true, message: "请输入标题", trigger: "blur" }],
        title_en: [{ required: true, message: "请输入英文标题", trigger: "blur" }],
        pic_cn: [{ required: true, message: "请上传轮播图" }],
        pic_en: [{ required: true, message: "请上传英文轮播图" }],
        sort: [{ required: true, message: "请输入排序编号", trigger: "blur" }],
      },
      picList: [],
      enPicList: [],
      userTags: UserTagMap,
      platformTags: PlatformTagMap,
      positionId: "",
    };
  },
  mounted() {
    if (this.type === "edit") {
      this.form = Object.assign(
        {},
        {
          ...this.data,
          start_at: (this.data.start_at || 0) * 1000,
          end_at: (this.data.end_at || 0) * 1000,
        },
      );
      if (this.data.pic_cn) {
        this.picList = [
          {
            url: this.data.pic_cn,
          },
        ];
      }

      if (this.data.pic_en) {
        this.enPicList = [
          {
            url: this.data.pic_en,
          },
        ];
      }
    }
    this.positionId = this.id;
  },
  methods: {
    onSubmit() {
      console.log("onSubmit", { ...this.form });

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      if (this.form.start_at > this.form.end_at) {
        this.$message.error("开始时间不能大于结束时间");
        return;
      }

      const action = this.type === "add" ? this.add : this.update;
      const success = await action();
      if (success) {
        this.$emit("success");
      }
    },

    async add() {
      try {
        const bannerData = this.getBannerData();
        await addBanner(bannerData);
        return true;
      } catch (error) {
        return false;
      }
    },
    async update() {
      try {
        const id = this.form.id;
        const bannerData = this.getBannerData();
        await updateBanner(id, bannerData);
        return true;
      } catch (error) {
        return false;
      }
    },

    getBannerData() {
      return {
        title_cn: this.form.title_cn,
        title_en: this.form.title_en,
        sub_title_cn: this.form.sub_title_cn,
        sub_title_en: this.form.sub_title_en,
        button_text_cn: this.form.button_text_cn,
        button_text_en: this.form.button_text_en,
        button_link_cn: this.form.button_link_cn,
        button_link_en: this.form.button_link_en,
        pic_cn: this.form.pic_cn,
        pic_en: this.form.pic_en,
        sort: +this.form.sort,
        status: +this.form.status,
        link: this.form.link,
        link_en: this.form.link_en,
        start_at: (this.form.start_at || 0) / 1000,
        end_at: (this.form.end_at || 0) / 1000,
        user_label: this.form.user_label,
        platform_label: this.form.platform_label,
        position_id: this.positionId,
        // banner_type: this.form.banner_type,
      };
    },

    // 上传和删除
    handleUploadSuccess(res, file) {
      this.form.pic_cn = res.data;
      this.$refs.form.clearValidate("pic_cn");
    },
    handleRemove(file, fileList) {
      this.form.pic_cn = "";
    },
    handleEnUploadSuccess(res, file) {
      this.form.pic_en = res.data;
      this.$refs.form.clearValidate("pic_en");
    },
    handleEnRemove(file, fileList) {
      this.form.pic_en = "";
    },
  },
};
</script>
